import { baseApi } from 'project/api';
import { BaseComponent } from 'project/general';
import { BaseForm } from 'shared/base-form';
import { gtmService } from 'project/services';
import { app } from 'general';

const ENCTYPE_JSON = 'json';

class DonationFormComponent extends BaseComponent {
    static getNamespace() {
        return 'donation-form';
    }


    onInit() {
        this.lastValue = '';
        this.formInstance = new BaseForm(this.element, {
            submitElement: this.element.querySelector('button[type="submit"]'),
            onSuccessfulSubmit: (response) => {
                this.trackDonation();
                if (response.data.success && response.data.redirectUrl) {
                    window.location.href = response.data.redirectUrl;
                }
            },
            onFailedSubmit: (error) => {
                console.error(error);
            },
            enctype: ENCTYPE_JSON,
            axiosInstance: baseApi,
            async: true,
            parsley: {
                errorClass: 'is-invalid',
                successClass: 'is-valid',
                errorsWrapper: '<div class="donation-form__error" aria-live="assertive"></div>',
                errorTemplate: '<div></div>',
            }
        });

        if (this.refs.fixedOptions && !Array.isArray(this.refs.fixedOptions)) {
            this.refs.fixedOptions = [this.refs.fixedOptions];
        }

        if (this.refs.fixedOptions) {
            this.refs.fixedOptions.forEach((fixedOption) => {
                this.addListener(fixedOption, 'change', this.onChangeOption);
            });
        }

        let otherAmountOptionName = '';

        if (this.refs.otherAmountOption) {
            this.addListener(this.refs.otherAmountOption, 'change', this.onChangeOption);
            otherAmountOptionName = this.refs.otherAmountOption.name;
        }

        if (this.refs.otherAmountInput) {
            this.addListener(this.refs.otherAmountInput, 'input', this.onInputOtherAmount);
            this.addListener(this.refs.otherAmountInput, 'focus', this.onFocusOtherAmount);
        }

        this.addListener(this.element, 'submit', this.onSubmitRoot);

    }

    onChangeOption = (e) => {
        // if 'other amount' hidden option unchecked (due to checking
        // one of the fixed options) populate 'other amount' input with pre-defined amount
        if (!this.refs.otherAmountOption.checked) {
            this.prepopulateOtherAmount(e.target);
        }
    };

    prepopulateOtherAmount(sourceAmountOption) {
        const amount = Number(sourceAmountOption.dataset.amount);

        if (amount) {
            this.refs.otherAmountInput.value = amount;
            this.refs.otherAmountInput.dispatchEvent(new Event('input'));
        }
    }

    onFocusOtherAmount = () => {
        // if 'other amount' input is focused it means user
        // selected 'other amount' option
        this.refs.otherAmountOption.checked = true;
    };

    onInputOtherAmount = () => {
        const matched = this.refs.otherAmountInput.value.match(/^(\d*?(?:\.\d{0,2})?)$/);
        if (matched) {
            this.lastValue = this.refs.otherAmountInput.value;
        } else {
            this.refs.otherAmountInput.value = this.lastValue;
        }
    };

    trackDonation() {
        const page = app.getConfig('page');
        const value = this.getTrackingDonationValue();
        gtmService.pushEvent(this.options.type, value, `Donate > ${page}`);
    }

    getTrackingDonationValue() {
        if (this.refs.otherAmountInput !== undefined) {
            return this.refs.otherAmountInput.value;
        }

        return this.options.value;
    }
}

export default DonationFormComponent;
